import React, { useEffect, useState, useRef, useContext, useMemo, useCallback } from "react";
import {
  Row,
  Col,
  Dropdown,
  Form,
  ToggleButton,
  Modal,
  ButtonGroup,
  Spinner,
  Button,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import APIServicenew from "../utils/APIGeneralService";
import { formateDate, getCookies, setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Papa from "papaparse";
import { Trash2, PencilFill, TrashFill, Pencil } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import BookingModal from "../components/Shared/BookingModal";
import { useToasts } from 'react-toast-notifications';
import Select, { components } from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import DynamicDataTableWithCount from "../components/dataTableWithCount";
import { FaSistrix } from 'react-icons/fa';


const PujaAllBooking = () => {
  document.title = "Puja Bookings";
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_REST_API_URL);

  const APIServiceschatbot = new APIServicenew(
    process.env.REACT_APP_OTHER_API_URL
  );
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalChats, setTotalChats] = useState("");
  const [paidtotalChats, setPaidTotalChats] = useState("");
  const token = getCookies("accessToken");
  const tokenlead = getCookies("accessTokenleads");
  const Email = getCookies("astroResponderEmailleads")

  const { refreshToken, countryList } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const searchKeyword = useRef(null);
  const [allbooking, setAllBooking] = useState([]);
  const [allbookingdownload, setAllBookingDownload] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [userForNote, setUserForNote] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [note, setNote] = useState("");
  const [loader, setLoader] = useState(false);
  const [puja, setPuja] = useState([]);
  const selectedPuja = useRef(null);
  const [totalAmount, setTotalAmount] = useState('')

  // Add puja booking modal
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  const [pujaId, setPujaId] = useState();
  const [bookingNumber, setBookingNumber] = useState('');

  const handleBookingSubmit = async data => {
    try {

      let memebersData = [];
      if (data?.memberName && data?.memberName.length > 0) {
        data?.memberName.forEach((name, i) => {
          memebersData[i] = {
            name: name,
            gotra: data?.memberGotra?.[i],
          };
        });

        delete data?.memberName;
        delete data?.memberGotra;
      } else {
        memebersData = [{
          name: data?.name,
          gotra: data?.gotra,
        }];
      }

      let res;
      if (!selectedBooking) {
        const payload = {
          ...data,
          members: memebersData,
          contactNumber: data.phoneCode.replace("+", "") + data.contactNumber,
          prasad_add: data?.prasad_add?.value,
          dakshina_add: data?.dakshina_add?.value,
          prasad_price: data?.prasad_price,
          dakshina_price: data?.dakshina_price,
          pujaId: pujaId,
          pujaPlan: data?.pujaPlan?.value,
          responderId: adminUser?._id,
          type: "Lead",
          total_price: totalAmount,
          phoneCode: data?.phoneCode,
          countryCode: data?.countryCode,
          paymentStatus: "Paid"
        };
        res = await APIServices.post('lead/pujaBooking', payload, headerslead);
      }


      if (res && res.status === 200) {
        toast.success(res?.data?.message);
        // fetchBookingsByPagination(skip, limit, pageIndex);
        handleBookingModalClose();

        setSelectedBooking(false);
      } else {
        toast.error(res?.data?.message ? res?.data?.message : 'Something went wrong');
      }
    }
    catch (error) {
      toast.error(error?.message ? error?.message : 'Something went wrong');
    } finally {
    }
  };



  const handleBookingModalClose = () => {
    setShowBookingModal(false);
    setSelectedBooking(null);
  };

  // calling states & functions

  const [dial, setDail] = useState("Call");
  const [showCallCm, setShowCallCm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const handleCloseCallCm = () => {
    setShowCallCm(false);
  };

  const makeCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/call",
      {
        userId: currentUserId,
        source: "puja",
      },
      headerslead
    );
    if (res && res.status === 200) {
      setDail(res?.data?.message);
      setShowCallCm(false);
      addToast("Calling....", {
        appearance: 'success',
        autoDismiss: true
      })
      setTimeout(() => {
        setDail("Call");
      }, 10000);
    }
  };

  const handleConfirm = () => {
    makeCall();
  };
  const headerslead = {
    Authorization: `Bearer ${tokenlead}`,
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [flag, setFlag] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(40);
  const [searchKey, setSearchKey] = useState('');
  const [countData, setCountData] = useState()
  const [source, setSource] = useState('');
  const handleSourceChange = e => {
    setSource(e.target.value);
    setOffset(0);
  };

  const [payment, setPayment] = useState('');
  const handlePaymentChange = e => {
    setPayment(e.target.value);
    setOffset(0);
  };
  const [country, setCountry] = useState('');
  const handleSelectChange = selected => {
    setCountry(selected);
    setOffset(0);
  };

  const options = countryList.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({
    value: item.name,
    label: item.name,
  }));

  const [notes, setNotes] = useState('');
  const handleNotesChange = e => {
    setNotes(e.target.value);
    setOffset(0);
  };

  const [prasadAdd, setPrasadAdd] = useState('');
  const handlePrasadChange = e => {
    setPrasadAdd(e.target.value);
    setOffset(0);
  };

  const [dakshinaAdd, setDakshinaAdd] = useState('');
  const handleDakshinaChange = e => {
    setDakshinaAdd(e.target.value);
    setOffset(0);
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");

  const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  // const [inputState, setInputState] = useState({
  //   startdate: "",
  //   enddate: '',
  // });

  // const handleInputChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, startdate: formattedDate }));
  // };

  // const handleInputFromChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, enddate: formattedDate }));
  // };

  const [inputStateP, setInputStateP] = useState({
    pujaDate: "",
  });

  const handleInputChangeP = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setInputStateP(prevState => ({ ...prevState, pujaDate: formattedDate }));
  };


  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSelectedRange("")
    setInputStateP({
      pujaDate: '',
    });
    // selectedPujaSec.current=""
    setSelectedPujaSec({ current: '' });
    setSource('');
    setPayment('');
    setCountry('');
    setNotes('');
    setSearchKey('');
    setPrasadAdd('');
    setDakshinaAdd('');
    setBookingNumber('')
    setFlag(true);
  };

  const handleFilterClick = () => {
    if (
      startDate ||
      endDate ||
      inputStateP?.pujaDate ||
      perPage ||
      offset ||
      pageIndex ||
      source ||
      payment ||
      country ||
      notes ||
      searchKey ||
      prasadAdd ||
      dakshinaAdd || bookingNumber ||
      selectedPujaSec?.current
    ) {
      fetchedAllBooking(
        offset,
        perPage,
        0,
        startDate,
        endDate,
        inputStateP?.pujaDate,
        source,
        payment,
        country,
        notes,bookingNumber,
        searchKey,
        prasadAdd,
        dakshinaAdd,
        selectedPujaSec?.current
      );
    }
  };

  const Input = props => {
    const { autoComplete = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoComplete} />;
  };
  const [selectedPujaSec, setSelectedPujaSec] = useState({ current: '' });
  const pujaOptions = [
    { value: '', label: 'All' },
    ...puja.map(item => ({
      value: item._id,
      label: `${item.title} (${moment(item.pujaDate).format('lll')})`,
    })),
  ];

  const handleChangeSec = selectedOption => {
    if (selectedOption) {
      setSelectedPujaSec({ current: selectedOption.value });
      handlePujaChangeSec(selectedOption);
    } else {
      console.log('No option selected');
    }
  };

  const handlePujaChangeSec = selectedOption => {
    const value = selectedOption.value;
    setCookies('skip', 0);
    setSkip(0);
  };

  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };

  useEffect(() => {
    fetchedAllBooking(offset, perPage, pageIndex);
    fetchData();
    handleCountData()
  }, [flag]);



  const handleCountData = async (id) => {
    const res = await APIServices.get(`lead/getPujaCount`, headerslead);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };


  const handleDownload = (data) => {

    for (let puja of data) {
      if (puja.pujaId) {
        puja['Puja Name '] = puja?.pujaId?.title;
      }
      if (puja.bookingNumber) {
        puja['Booking Number '] = puja?.bookingNumber;
      }
      
      if (puja.name) {
        puja['Name'] = puja.name;
      } else {
        puja['Name'] = "-";
      }
      if (puja.gotra) {
        puja['Gotra'] = puja.gotra;
      } else {
        puja['Gotra'] = "-";

      }
      if (puja.address) {
        puja['Address'] = puja.address;
      } else {
        puja['Address'] = "-";

      }
      if (puja.pujaPlan) {
        puja['PujaPlan Name'] = puja?.pujaPlan.title ?? '';
        if (puja.currencySymbol) {
          puja['Currency Symbol'] = puja.currencySymbol;
        }
        puja['PujaPlan Price'] = puja?.pujaPlan?.price ?? 0;
      }
      if (puja.pujaId) {
        puja['Puja Date '] = moment(puja?.pujaId?.pujaDate).format('lll');
      }
      if (puja.prasad_add) {
        puja['Prasad Status'] =
          puja?.prasad_add === 'no'
            ? 'no'
            : puja?.prasad_price === 0
              ? "no"
              : "yes"
      }
      if (puja.dakshina_add) {
        puja['Dakshina Status'] =
          puja?.dakshina_add === 'no'
            ? 'no'
            : puja?.dakshina_price === 0
              ? "no"
              : "yes"
      }
      if (puja.paymentStatus) {
        puja['Payment Status'] = puja.paymentStatus;
      }
      if (puja.contactNumber) {
        puja['Contact Number'] = puja.contactNumber;
      }
      if (puja.type) {
        puja['Source'] = puja.type;
      }

      if (puja.createdAt) {
        puja['Booking date '] = moment(puja?.createdAt).format('lll');
      }

      if (puja?.members?.length > 0) {
        let nameStr = '';
        for (let userName of puja.members) {
          if (userName?.name) {
            nameStr += `${userName.name},`;
          }
        }

        puja['Members Name'] = nameStr?.replace(/,*$/, '');
      }
      if (puja.remark) {
        puja['Remark'] = puja.remark;
      }
      // if (puja.currency === 'INR') {
      //   puja['Price '] = `₹ ${puja?.Price} `;
      // } else {
      //   puja['Price '] = `$ ${puja?.Price} `;
      // }

      delete puja.pujaPlan;
      delete puja.pujaId;
      delete puja._id;
      delete puja.bookingNumber;
      delete puja.userId;
      delete puja?.members;
      delete puja.createdAt;
      // delete puja.Price;
      delete puja.status;
      delete puja.type;
      delete puja.contactNumber;
      delete puja.contactNumberMsk;
      delete puja.name;
      delete puja.gotra;
      delete puja.address;
      delete puja.paymentStatus;
      delete puja.remark;
      delete puja?.countryId;
      delete puja?.currency;
      delete puja?.currencySymbol;
      delete puja?.prasad_add;
      delete puja?.dakshina_add;
      delete puja?.prasad_price;
      delete puja?.dakshina_price;
      delete puja?.total_price
    }

    let csv = Papa.unparse(data, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (window.navigator.msSaveOrOpenBlob)
      window.navigator.msSaveBlob(csvData, "UserList.csv");
    else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
      a.download = "Pujabooking.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'NO.',
        accessor: 'id',
        Cell: e => (
          <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>
        ),
      },
      {
        Header: 'Booking Number',
        accessor: 'bookingno',
        Cell: e => <span>{e.row.original?.bookingno ?? '_'}</span>,
      },
      {
        Header: 'Puja Name',
        accessor: 'pujaId.title',
        Cell: e => <span>{e.row.original?.pujaId?.title ?? '_'}</span>,
      },
      ...(Email !== "gostrategyhubads@gmail.com"
        ? [{
          Header: 'Gotra',
          accessor: 'gotra',
          Cell: e => <span>{e.row.original?.gotra ?? '_'}</span>,
        }]
        : []),
      {
        Header: 'Name',
        accessor: 'name',
        Cell: e => <span>{e.row.original?.name ?? '_'}</span>,
      },
      ...(Email !== "gostrategyhubads@gmail.com"
        ? [{
          Header: 'Address',
          Cell: e => (
            <span>
              {e.row.original?.address ? e.row.original?.address : ''}
              {e.row.original?.city ? `, ${e.row.original?.city}` : ''}
              {e.row.original?.state ? `, ${e.row.original?.state}` : ''}
              {e.row.original?.country ? `, ${e.row.original?.country}` : ''}
              {e.row.original?.postalCode ? `, ${e.row.original?.postalCode}` : ''}
            </span>
          ),
        }]
        : []),
      ...(Email !== "gostrategyhubads@gmail.com"
        ? [{
          Header: 'Country Name',
          accessor: 'country',
          Cell: e => (
            <span>
              {e.row.original?.country ? `${e.row.original?.country}` : ''}
            </span>
          ),
        }]
        : []),
      {
        Header: 'PujaPlan Name',
        accessor: 'pujaPlan.title',
        Cell: e => <span>{e.row.original?.pujaPlan?.title ?? '_'}</span>,
      },
      ...(Email === "gostrategyhubads@gmail.com"
        ? [{
          Header: 'PujaPlan Price',
          Cell: e => {
            return <span>{e.row.original?.currencySymbol + e.row.original?.pujaPlan?.price ?? '_'}</span>;
          },
        },]
        : []),
      {
        Header: 'Puja Date',
        accessor: 'pujaId.pujaDate',
        Cell: e => (
          <span>
            {e.row.original ? moment(e.row.original?.pujaId?.pujaDate).format("D MMM, YYYY h:mm A") : '_'}
          </span>
        ),
      },
      {
        Header: 'Payment Status',
        Cell: e => (
          <span className={
            e.row.original.paymentStatus === "Paid" ? "text-success fw-bold font_14" :
              e.row.original.paymentStatus === "Failed" ? "text-danger fw-bold font_14" :
                "text-pending fw-bold font_14"
          }>
            {e.row.original.paymentStatus ?? "_"}
          </span>
        ),
      },
      ...(Email !== "gostrategyhubads@gmail.com"
        ? [{
          Header: 'Contact Number',
          accessor: 'contactNumber',
          Cell: e => <span>{e.row.original?.contactNumber ?? '_'}</span>,
        }]
        : []),
      {
        Header: 'Language',
        Cell: e => {
          return <span>{e.row.original?.language ?? '_'}</span>;
        },
      },
      {
        Header: 'Source',
        accessor: 'type',
        Cell: e => <span>{e.row.original?.type ?? '_'}</span>,
      },
      {
        Header: 'Booking Date',
        accessor: 'createdAt',
        Cell: e => (
          <span>{e.row.original ? moment(e.row.original.createdAt).format("D MMM, YYYY h:mm A") : '_'}</span>
        ),
      },
    ];

    // Conditionally add the "Notes" column, as you did before
    if (Email !== "gostrategyhubads@gmail.com") {
      baseColumns.push({
        Header: 'Notes',
        Cell: e => (
          <h5>
            <input
              type='button'
              className='Et-btn notes-btn'
              value='Notes'
              onClick={() => {
                setUserForNote(e.row.original);
                handleUserNotes(e.row.original.userId);
              }}
            />
            <button
              onClick={() => {
                setShowCallCm(true);
                setCurrentUserId(e.row.original.userId);
              }}
              className="mt-2 call_btn btn btn-secondary"
            >
              Call
            </button>
          </h5>
        ),
      });
    }

    return baseColumns;
  }, [Email]);   // Make sure to include Email in the dependencies

  //   // Conditionally add the "Notes" column, as you did before
  //   if (Email !== "gostrategyhubads@gmail.com") {
  //     baseColumns.push({
  //       Header: 'Notes',
  //       Cell: e => (
  //         <h5>
  //           <input
  //             type='button'
  //             className='Et-btn notes-btn'
  //             value='Notes'
  //             onClick={() => {
  //               setUserForNote(e.row.original);
  //               handleUserNotes(e.row.original.userId);
  //             }}
  //           />
  //           <button
  //             onClick={() => {
  //               setShowCallCm(true);
  //               setCurrentUserId(e.row.original.userId);
  //             }}
  //             className="mt-2 call_btn btn btn-secondary"
  //           >
  //             Call
  //           </button>
  //         </h5>
  //       ),
  //     });
  //   }

  //   return baseColumns;
  // }, [Email]);   // Make sure to include Email in the dependencies


  const handleNotesAdd = async () => {
    if (!note || note === "") {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.userId,
        id: userForNote?.pujaId?._id,
        source: "Leads-Puja-Booking",
        name: userForNote?.pujaId?.title,
        type: "respnder",
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      // console.log(payload);
      // return 
      const res = await APIServices.post(
        `userNotes/userNoteMessage`,
        payload,
        headerslead
      );

      if (res && res?.data?.status === true) {
        toast.success("Note added successfully");

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote("");
        setLoader(false);
      } else {
        toast.error(res?.data?.message);

        setLoader(false);
      }
      setLoader(false);
    }
  };
  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
    setNote("");
  };

  const handleUserNotes = async (id) => {
    const res = await APIServices.get(`userNotes/getDetailById/${id}`, headerslead);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log("error");
      }
    }
  };

  useEffect(() => {
    authorizeResponder()
  }, [])

  const authorizeResponder = async () => {
    const res = await APIServiceschatbot.post(
      "responders/authorize_responder",
      {
      },
      headerslead
    );
    if (res.data?.status == 4) {
      logout();
    }
  }

  const fetchData = async () => {
    let response = await APIServices.get(`astroPooja/pujalisting`, headerslead);
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setPuja(apiData.Listdata);
      }
    }
  };
  const fetchedAllBookingDownload = async () => {
    let countryId = country?.value === undefined ? '' : country?.value;
    const res = await APIServices.get(
      `/lead/getAllPujaBooking?searchKey=${searchKey}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&pujaDate=${inputStateP?.pujaDate}&source=${source}&pujaId=${selectedPujaSec?.current}&status=${payment}&country=${countryId}&isNotes=${notes}&prasad_add=${prasadAdd}&dakshina_add=${dakshinaAdd}`,
      headerslead
    );
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        setAllBookingDownload(resData?.Listdata);
        handleDownload(resData?.Listdata);
      }
    }
  };

  const fetchedAllBooking = useCallback(
    async (offset, perPage, index) => {
      try {
        // let page =offset/perPage
        let countryId = country?.value === undefined ? '' : country?.value;
        let res = await APIServices.get(
          `/lead/getAllPujaBooking/${offset}/${perPage}?searchKey=${searchKey}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&pujaDate=${inputStateP?.pujaDate}&source=${source == "all" ? "" : source}&pujaId=${selectedPujaSec?.current}&status=${payment == "all" ? "" : payment}&country=${countryId}&isNotes=${notes == "all" ? "" : notes}&prasad_add=${prasadAdd == "all" ? "" : prasadAdd}&dakshina_add=${dakshinaAdd == "all" ? "" : dakshinaAdd}&bookingno=${bookingNumber}`,
          headerslead
        );
        let data = res?.response?.data;
        if (res && res.status === 200) {
          let resData = res.data;
          if (resData && resData.status == true) {
            let newData = [...resData.Listdata];
            setUsers(newData);
            setTotalChats(resData.totalCount[0]?.totalCount);
            setPaidTotalChats(resData.paidcounts)
            setPageCount(Math.ceil(resData.totalCount[0]?.totalCount / perPage));
            setFlag(false);
          } else {
            setUsers([]);
            setTotalChats("");
            setPaidTotalChats("")
            setPageCount(0);
            setFlag(false);
          }
        } else {
          if (data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setOffset(offset);
      setPerPage(perPage);
      setPageIndex(index);
    },
    [
      pageIndex,
      offset,
      searchKey,
      selectedPujaSec?.current,
      startDate,
      endDate,
      inputStateP?.pujaDate,
      source,
      payment,
      country,
      notes,
      prasadAdd,
      dakshinaAdd,
      bookingNumber
    ]
  );





  return (
    <>
      <div className="gita_quote_heading">
        <div className="container">
          <div className="overflow-x-auto">
            <div className="d-flex counter-wrapper gap-2 align-items-center justify-content-between">
              <h4 className="hd-list-f font_20 left-q counter-top">
                All Puja Bookings <small>{paidtotalChats}/{totalChats}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                Today Puja <small>{countData?.usersAddedPaidToday}/{countData?.usersAddedToday}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Week Puja <small>{countData?.usersAddedPaidThisWeek}/{countData?.usersAddedThisWeek}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Month Puja <small>{countData?.paidPujaThisMonth}/{countData?.usersAddedThisMonth}</small>
              </h4>
              {
                Email == "gostrategyhubads@gmail.com" ? "" : <div className="_btn">
                  <Button
                    className="by-btn as_btn"
                    variant="primary"
                    onClick={() => {
                      setShowBookingModal(true);
                    }}
                  >
                    Add puja booking
                  </Button>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
      <div className="chat_window">
        <Row className="mlist my-3 br-6 p-20">
          <div className="chat_search_box user-search">
            <div className="chat-search-field">
              <span className="chat-bot-search-icon">
                {/* <i className="fa fa-search"></i> */}
                <FaSistrix />
              </span>
              <input
                type="text"
                className="form-control form-input name-search"
                placeholder={Email != "gostrategyhubads@gmail.com" ? "Search by name and contact no." : "Search by name "}
                onChange={e => {
                  setSearchKey(e.target.value);
                  setOffset(0);
                }}
                value={searchKey}
              />
            </div>
            <hr />

            <Row className="align-items-end">
              <Col lg={3} md={6} className="mb-3">
                <Form.Label>Source</Form.Label>
                <Form.Select
                  aria-label="label-selected-menu"
                  className="fw-bold label-selected-menu text-capitalize  form-ch w-50"
                  onChange={e => {
                    handleSourceChange(e);
                  }}
                  value={source}
                >
                  <option className="fw-bold" value="all">
                    All
                  </option>
                  <option className="text-capitalize" value="app">
                    App
                  </option>
                  <option className="text-capitalize" value="Admin">
                    Admin
                  </option>
                  <option className="text-capitalize" value="Lead">
                    Lead
                  </option>
                  <option className="text-capitalize" value="Web">
                    Web
                  </option>
                  <option className="text-capitalize" value="Global">
                    Global
                  </option>
                </Form.Select>
              </Col>
              <Col lg={3} md={6} className="mb-3">
                <Form.Label>Payment Status</Form.Label>
                <Form.Select
                  aria-label="label-selected-menu"
                  className="fw-bold label-selected-menu text-capitalize  form-ch  w-50"
                  onChange={e => {
                    handlePaymentChange(e);
                  }}
                  value={payment}
                >
                  <option className="fw-bold" value="all">
                    All
                  </option>
                  <option className="text-capitalize" value="Failed">
                    Failed
                  </option>
                  <option className="text-capitalize" value="Pending">
                    Pending
                  </option>
                  <option className="text-capitalize" value="Paid">
                    Paid
                  </option>
                </Form.Select>
              </Col>
              <Col lg={3} md={6} className="mb-3">
                <Form.Label>Puja</Form.Label>
                {/* <Form.Select
                  aria-label="label-selected-menu"
                  className="fw-bold label-selected-menu text-capitalize  w-50  form-ch"
                  onChange={(e) => {
                    handlePujaChange(e);
                    setSkip(0);
                  }}
                  value={selectedPuja.current}
                >
                  <option className="fw-bold" value="all">
                    All
                  </option>
                  {puja.map((item, index) => {
                    return (
                      <>
                        <option className="text-capitalize" value={item._id}>
                          {item.title} ({moment(item.pujaDate).format("lll")})
                        </option>
                      </>
                    );
                  })}
                </Form.Select> */}
                <Select
                  components={{ Input }}
                  autoComplete='new-password'
                  options={pujaOptions}
                  // value={pujaOptions.find(option => option.value === selectedPujaSec.current)}
                  value={
                    selectedPujaSec.current === ''
                      ? ''
                      : pujaOptions.find(option => option.value === selectedPujaSec.current)
                  }
                  onChange={handleChangeSec}
                  classNamePrefix='label-selected-menu'
                // className='fw-bold text-capitalize custom-datepicker'
                />
              </Col>
              <Col lg={3} md={6} className="mb-3 justify-content-end align-items-end">
                <Form.Label>Puja Date</Form.Label>

                <DatePicker
                  placeholderText={'Select a puja date'}
                  className='form-control form-ch fil-date-pick '
                  name='pujaDate'
                  selected={inputStateP.pujaDate ? new Date(inputStateP.pujaDate) : null}
                  onChange={date => {
                    handleInputChangeP(date);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col>



              {/* <Col lg={2} md={6} className=" justify-content-end align-items-end">
                <Form.Label className='mt-4'>From Date</Form.Label>

                <DatePicker
                  placeholderText={'Select a from date'}
                  className='form-control form-ch fil-date-pick '
                  name='startdate'
                  selected={inputState.startdate ? new Date(inputState.startdate) : null}
                  onChange={date => {
                    handleInputChange(date);
                  }}
                  dateFormat='yyyy-MM-dd'
                />

              </Col>
              <Col lg={2} md={6} className=" justify-content-end  align-items-end">
                <Form.Label className='mt-4'>To Date</Form.Label>

                <DatePicker
                  placeholderText={'Select a to date'}
                  className='form-control form-ch fil-date-pick '
                  name='enddate'
                  selected={inputState.enddate ? new Date(inputState.enddate) : null}
                  onChange={date => {
                    handleInputFromChange(date);
                    // setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />

              </Col> */}

              <Col lg={3} md={6} className='mb-3'>
                <Form.Label className='label-f-w-cd'>
                  Country
                </Form.Label>
                <Select
                  className=' mt-2'
                  classNamePrefix='react-select'
                  options={options}
                  value={country}
                  onChange={handleSelectChange}
                  placeholder='Search by Country...'
                  isClearable
                />
              </Col>
              {/* <Col lg={2} md={6} className='justify-content-end pe-0 align-items-end'>
                 <Form.Label>End Date</Form.Label>
               </Col>
               <Col lg={2} md={6}>
                 <input
                   className='form-control'
                   name='enddate'
                   type='date'
                   onChange={e => handleInputChange(e)}
                   value={inputState.enddate}
                 />
               </Col> */}
              {Email != "gostrategyhubads@gmail.com" &&
                <><Col lg={3} md={6} className="mb-3">
                  <Form.Label> Notes</Form.Label>

                  <Form.Select
                    aria-label="label-selected-menu"
                    className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr  form-ch"
                    onChange={e => {
                      handleNotesChange(e);
                    }}
                    value={notes}
                  >
                    <option className="fw-bold" value="all"> All</option>
                    <option className="text-capitalize" value="yes">
                      Yes{" "}
                    </option>
                    {/* <option className='text-capitalize' value='no'> No </option> */}
                  </Form.Select>
                </Col>
                  <Col lg={3} md={6} className="mb-3">
                    <Form.Label> Prasad Add</Form.Label>

                    <Form.Select
                      aria-label='label-selected-menu'
                      className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr  form-ch'
                      onChange={e => {
                        handlePrasadChange(e);
                      }}
                      value={prasadAdd}
                    >
                      <option className='fw-bold' value="all">
                        All
                      </option>
                      <option className='text-capitalize' value='yes'>
                        Yes
                      </option>
                      <option className='text-capitalize' value='no'>
                        No
                      </option>
                    </Form.Select>
                  </Col>
                  <Col lg={3} md={6} className="mb-3">
                    <Form.Label> Dakshina Add</Form.Label>

                    <Form.Select
                      aria-label='label-selected-menu'
                      className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr  form-ch'
                      onChange={e => {
                        handleDakshinaChange(e);
                      }}
                      value={dakshinaAdd}
                    >
                      <option className='fw-bold' value="all">
                        All
                      </option>
                      <option className='text-capitalize' value='yes'>
                        Yes
                      </option>
                      <option className='text-capitalize' value='no'>
                        No
                      </option>
                    </Form.Select>
                  </Col></>}

              <Col lg={3} md={6} className="mb-3">
                <Form.Label>Select By Booking Date</Form.Label>
                <div className="date-range-wrapper">
                  <div className='select-icon-wrapper'>
                    <select
                      className="select-range"
                      value={selectedRange}
                      onChange={(e) => handleDateRange(e.target.value)}
                    >
                      <option value="">Select Date Range</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="thisWeek">This Week</option>
                      <option value="last7Days">Last 7 Days</option>
                      <option value="thisMonth">This Month</option>
                      <option value="lastMonth">Last Month</option>
                      <option value="customDate">Custom Date</option>
                    </select>
                    <img src='/images/down-arrow.png' className='select-icons' />
                  </div>

                  {selectedRange === "customDate" && (
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={new Date()}
                      onChange={(update) => {
                        const [start, end] = update;
                        setStartDate(start);
                        setEndDate(end);
                      }}
                      // isClearable={true}
                      placeholderText="Select custom date range"
                      dateFormat="yyyy-MM-dd"
                    />
                  )}
                </div>
              </Col>

              <Col lg={3} md={6} className='mb-3'>
                  <Form.Label className=''>Booking Number</Form.Label>

                    <Form.Control
                      className=' mt-0 form-ch'
                      name='bookingNumber'
                      type='search'
                      placeholder='Search By Booking number'
                      value={bookingNumber}
                      onChange={e => {
                        setBookingNumber(e.target.value);
                        setOffset(0);
                      }}
                    />
                  </Col>
              <Col lg={6} md={6} className=" mb-3 d-flex flex-wrap  ps-0">
                <Button
                  variant='secondary'
                  className='as_btn'
                  onClick={() => {
                    handleFilterClick();
                    setOffset(0);
                  }}
                >
                  Filter
                </Button>
                <Button
                  className="as_btn ms-2 reset-btn"
                  variant="primary"
                  onClick={handleReset}
                >
                  Reset
                </Button>

                {/* <Button
                  className="as_btn ms-2 download-btn"
                  variant="primary"
                  onClick={() => {
                    fetchedAllBookingDownload();
                  }}
                >
                  Download Booking
                </Button> */}
              </Col>
            </Row>
          </div>

        </Row>
        <div className='puja_booking_table'>
          {users && users.length > 0 ? (
            <DynamicDataTableWithCount
              columns={columns}
              data={users}
              totalCount={pageCount}
              initialState={{
                pageIndex: pageIndex,
                pageSize: perPage,
              }}
              fetchData={fetchedAllBooking}
            // forcePage={offset / perPage}
            />
          ) : (
            <h2 className='no_records'>Records not Available</h2>
          )}
        </div>

      </div>
      <Modal
        show={show}
        size="md"
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop="static"
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="aa d-flex">
            <span className="me-3"> Name :- {userForNote?.name} </span>
            <span> Contact Number :- {userForNote?.contactNumber} </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className="my-3" controlId="formAartiInput">
                <Col sm="12">
                  <Form.Control
                    as="textarea"
                    name="content"
                    placeholder="Type here..."
                    value={note}
                    style={{ height: 100 }}
                    onChange={(e) => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? (
                    <p className="text-danger">Please enter your note</p>
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
            {loader ? (
              <Button variant="primary" className="as_btn reset-btn">
                Processing...
              </Button>
            ) : (
              <Button
                variant="primary"
                className="as_btn"
                onClick={handleNotesAdd}
              >
                Add
              </Button>
            )}
            <Button
              variant="secondary"
              className="as_btn reset-btn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>

          <h6 className="aa mt-2"> Note History :- </h6>

          {notesData && notesData?.notes?.length > 0 ? (
            <>
              {notesData?.notes?.map((item, index) => (
                <>
                  <Row>
                    {item?.source && item?.source != "" ?
                      <Col xs={5}>
                        <span className="aa">Source :- </span>
                        <span className="item-msg">{item.source ? item.source : ""} </span>
                      </Col> : ""
                    }
                    {item?.source && item?.source !== "" && (
                      (item.source === "Leads-Chat-Orders" || item.source === "Leads-Call-Orders") ? (
                        item?.sourcename && item.sourcename !== "" && (
                          <Col xs={7}>
                            {(item.source === "Leads-Chat-Orders") ? (
                              <span className="aa">Chat Id :- </span>
                            ) : (
                              <span className="aa">Call Id :- </span>
                            )}
                            <span className="item-msg">
                              {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                            </span>
                          </Col>
                        )
                      ) : (item.source === "Leads-Chadhawa-List" ||
                        item.source === "Leads-Manokamna-List" ||
                        item.source === "Marketing-Chadhawa-List" ||
                        item.source === "Marketing-Manokamna-List") ? (
                        item?.sourceid && item.sourceid !== "" && (
                          <Col xs={7}>
                            {(item.source === "Leads-Chadhawa-List" || item.source === "Marketing-Chadhawa-List") ? (
                              <span className="aa">Chadawa Id :- </span>
                            ) : (
                              <span className="aa">Manokmana Id :- </span>
                            )}
                            <span className="item-msg">
                              {item?.sourceid?.length <= 50 ? item?.sourceid : `${item?.sourceid?.substring(0, 40)}...`}
                            </span>
                          </Col>
                        )
                      ) : (item.source === "Leads-Puja-Booking" ||
                        item.source === "Leads-Puja-Suggestion" ||
                        item.source === "Marketing-Puja-Booking" ||
                        item.source === "Marketing-Puja-Feedback" ||
                        item.source === "Marketing-Puja-Suggestion") ? (
                        item?.sourcename && item.sourcename !== "" && item?.sourceid && (
                          <Col xs={7}>
                            <span className="aa">Puja Name :- </span>
                            <span className="item-msg">
                              {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                            </span>
                          </Col>
                        )
                      ) :
                        item.source === 'Marketing-Reports-Inquiry'
                          || item.source === "Leads-Reports-Inquiry" ?
                          <Col xs={7}>
                            <span className='aa'>Reports Name :- </span>
                            <span className='item-msg'>

                              {item?.sourcename?.length <= 50
                                ? item?.sourcename
                                : `${item?.sourcename?.substring(0, 40)}...`}
                            </span>
                          </Col> : null
                    )}
                  </Row>
                  <Row className="notes-item">
                    <Col xs={9}>
                      <span className="item-name aa">
                        Note :-
                      </span>
                      <span className="item-msg">{item?.message}</span>
                    </Col>
                    <Col xs={3}>
                      <div className="text-end d-flex flex-column">
                        <span className="text-primary item-name aa">
                          {item?.responderName || item?.adminName}
                        </span>
                        <span className="item-text">
                          {item?.createdAt
                            ? moment(item?.createdAt).format("D MMM, YYYY h:mm a")
                            : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <h4 className="text-center text-secondary">Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* call confirmation */}
      <Modal
        show={showCallCm}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm}
        centered
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to make a call?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn reset-btn"
            onClick={handleCloseCallCm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //booking modal */}

      <BookingModal
        show={showBookingModal}
        handleClose={() => handleBookingModalClose()}
        // pujaData={pujaData}
        onSubmit={handleBookingSubmit}
        pujaListing={puja}
        pujaId={pujaId}
        setPujaId={setPujaId}
        totalAmount={totalAmount}
        setTotalAmount={setTotalAmount}
        selectedBooking={selectedBooking}
        countryList={countryList}
      />

      <ToastContainer />
    </>
  );
};

export default PujaAllBooking;

// chat_window after div row className add when table not full show
